<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>你好啊，很高兴认识你</p>
    <h3>自我介绍</h3>
    <ul>
      <li class="v-li">👋 &nbsp;你好，我是@陈怀哲</li>
      <li class="v-li">📪 &nbsp;我在深圳市宝安区</li>
      <li class="v-li">👀 &nbsp;我对看电影、跑步、编码、科技数码等感兴趣</li>
      <li class="v-li">
        🖥 &nbsp;我目前的行业：<strong>智能穿戴、智能硬件、软件开发</strong>
      </li>
      <li class="v-li">🌱 &nbsp;我目前正在学习跳绳、Web3</li>
      <li class="v-li">💞️ &nbsp;欢迎各个可能方向上的交流和合作</li>
      <li class="v-li">🤙 &nbsp;如何联系我: 公众号：我是陈大壮</li>
    </ul>

    <!-- <h3>Essential Links</h3>
    <ul>
      <li>
        <a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a>
      </li>
      <li>
        <a href="https://forum.vuejs.org" target="_blank" rel="noopener"
          >Forum</a
        >
      </li>
      <li>
        <a href="https://chat.vuejs.org" target="_blank" rel="noopener"
          >Community Chat</a
        >
      </li>
      <li>
        <a href="https://twitter.com/vuejs" target="_blank" rel="noopener"
          >Twitter</a
        >
      </li>
      <li>
        <a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 400px;
  margin: auto;
}
h3 {
  margin: 40px 0 0;
  text-align: left;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.v-li {
  display: block;
  margin: 0 0px;
  text-align: left;
}
a {
  color: #42b983;
}
</style>
